import React from "react"
import { Link } from "gatsby"
import Logo from "src/images/logotype_rgb_white.svg"

class NavBar extends React.Component {
  state = { showMenu: false }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    })
  }

  render() {
    const menuActive = this.state.showMenu ? "is-active" : ""
    const burgerActive = this.state.showMenu ? "is-active" : ""
    const isFixed = this.props.fixed ? "is-fixed-top" : ""
    return (
      <nav
        className={`navbar ${isFixed} is-transparent`}
        id="navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={Logo} width="160" alt="natadeCOCO" />
          </Link>
          <div
            role="button"
            className={`navbar-burger burger has-text-white ${burgerActive}`}
            tabIndex="0"
            aria-label="menu"
            aria-expanded="false"
            data-target="navMenu"
            onClick={this.toggleMenu} onKeyDown={this.toggleMenu}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${menuActive}`}>
          <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen">
                製品
              </div>
              <div className="navbar-dropdown" style={{ borderTop: "none" }}>
                <Link
                  className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
                  onClick={this.toggleMenu}
                  to="/product/pocket/"
                >
                  ナタデココ（スマホアプリ）
                </Link>
                <Link
                  className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
                  onClick={this.toggleMenu}
                  to="/product/console/"
                >
                  管理コンソール（ウェブアプリ）
                </Link>
                <Link
                  className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
                  onClick={this.toggleMenu}
                  to="/product/unit/"
                >
                  配信ユニット
                </Link>
              </div>
            </div>
            <Link
              className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
              onClick={this.toggleMenu}
              to="/flow/"
            >
              ご利用の流れ
            </Link>
            <Link
              className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
              onClick={this.toggleMenu}
              to="/price/"
            >
              料金
            </Link>
            <Link
              className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
              onClick={this.toggleMenu}
              to="/casestudy/"
            >
              活用例
            </Link>
            <Link
              className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
              onClick={this.toggleMenu}
              to="/news/"
            >
              お知らせ
            </Link>
            <Link
              className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
              onClick={this.toggleMenu}
              to="/faq/"
            >
              よくあるご質問
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen">
                <a
                  className="has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
                  onClick={this.toggleMenu}
                  href="/guide/"
                >
                ドキュメント
                </a>
              </div>
              <div className="navbar-dropdown" style={{ borderTop: "none" }}>
                <a
                  className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
                  onClick={this.toggleMenu}
                  href="/guide/#/usage/"
                >
                  ユーザガイド
                </a>
              </div>
            </div>
            <Link
              className="navbar-item has-text-white has-text-weight-bold is-size-7 is-size-6-widescreen"
              onClick={this.toggleMenu}
              to="/contact/"
            >
              お問い合わせ
            </Link>
          </div>
          
          <div className="navbar-end">
            <div className="navbar-item">
              <p className="control">
                <a href="https://console.natade-coco.com" target="_blank" rel="noopener noreferrer">
                  <button className="button is-small is-info">
                    <span className="icon">
                      <i className="fas fa-power-off"></i>
                    </span>
                    <span><strong>管理コンソール</strong></span>
                  </button>
                </a>
              </p>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default NavBar
