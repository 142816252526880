import React from "react"
import Styles from "./styles.module.scss"

const Spacer = ({ isHalf, isSmall, className }) => {
  let type = Styles.spacerNormal
  if (isHalf) { type = Styles.spacerHalf }
  if (isSmall) { type = Styles.spacerSmall }

  return <div className={`${type} ${className}`}></div>
}

export default Spacer