import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Styles from "./footer.module.scss"

function Footer() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  return (
    <div>
      <footer className={`${Styles.footer}`}>
        <nav className={`${Styles.footerNav}`}>
          <div className={`${Styles.footerSection}`}>
            <h2>ナタデココについて</h2>
            <ul>
              <li>
                <OutboundLink
                  href="https://www.supersoftware.co.jp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  会社概要（運営会社）
                </OutboundLink>
              </li>
              <li>
                <Link to="/contact/inquery" replace>
                  <i className="fas fa-envelope"></i>&nbsp;お問い合わせ
                </Link>
              </li>
              <li>
                <OutboundLink href="https://twitter.com/nCOCOSupport">
                  <i className="fab fa-twitter"></i>&nbsp;公式Twitter
                </OutboundLink>
              </li>
              <li>
                <OutboundLink href="https://join.slack.com/t/natadecoco/shared_invite/enQtOTA3MDk5NDQ4MTk3LTE2OTIwZWM5MDNlNmU2NmYyZjcwMWVjYWUxOTFhNzM2MjU2NTdkYTY3ZTBhMzIwYmIzOGY5N2VlNzhlOWE1MDU">
                  <i className="fab fa-slack"></i>&nbsp;コミュニティ
                </OutboundLink>
              </li>
              {/* <li>
                <OutboundLink href="https://natadecoco.connpass.com/">
                  <i className="far fa-calendar-alt"></i>&nbsp;開催イベント
                </OutboundLink>
              </li> */}
              <li>
                <Link to="/assets" replace>
                  ブランドアセット
                </Link>
              </li>
            </ul>
          </div>
          <div className={`${Styles.footerSection}`}>
            <h2>プライバシーポリシーと利用規約</h2>
            <ul>
              <li>
                <OutboundLink
                  href="https://info.natade-coco.com/policies/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  プライバシーポリシー
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://info.natade-coco.com/terms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  利用規約
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://info.natade-coco.com/terms/provider/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  コンテンツ配信サービス利用規約
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://info.natade-coco.com/notations/sctl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  特定商取引に関する表記
                </OutboundLink>
              </li>
            </ul>
          </div>
        </nav>
        <div className={`${Styles.footerNotice}`}>
          <h2>ご利用上の注意点</h2>
          <ul>
            <li>
              アプリの利用料は無料ですが、ダウンロードやご利用時にかかるパケット通信料はお客さまのご負担となります。
            </li>
          </ul>
        </div>
        <div className={`${Styles.footerNotice}`}>
          <h2>商標について</h2>
          <ul>
            <li>
              Apple、Apple のロゴ、Apple Pay、Apple
              Watch、iPad、iPhone、iTunes、QuickTime、QuickTimeのロゴ、Safari、Mac
              OSは、米国および他の国々で登録されたApple
              Inc.の商標です。iPhoneの商標は、アイホン株式会社のライセンスにもとづき使用されています。App
              Store、AppleCare、iCloudは、Apple Inc.のサービスマークです。
            </li>
            <li>
              「Google」、「Google」ロゴ、「Googleマップ」、「Chromecast」、「Google
              Play」、「Google Play」ロゴ、「Google Play Music」、「Google Play
              Music」ロゴ、「Google Home」、「Google Home」ロゴ、「Google Home
              Mini」、「Google Home
              Mini」ロゴ、「Googleアシスタント」、「Android」、「Android」ロゴ、「YouTube」、「YouTube」ロゴ、「Gmail」、「Google
              Nest Mini」、「Google Nest Mini」ロゴは、Google
              LLCの商標または登録商標です。
            </li>
            <li>
              「Wi-Fi」、「Wi-Fi CERTIFIED Miracast™」、「Miracast™」はWi-Fi
              Allianceの登録商標です。
            </li>
            <li>
              「QRコード®」は、株式会社デンソーウェーブの商標または登録商標です。
            </li>
            <li>
              natadeCOCO、natadeCOCO
              のロゴは、株式会社スーパーソフトウエアの商標または登録商標です。
            </li>
          </ul>
        </div>
        <div className={`${Styles.footerLast}`}></div>
        <div className={`${Styles.copyright}`}>
          © {new Date().getFullYear()}
          {` `}
          {site.siteMetadata.author}
        </div>
      </footer>
    </div>
  )
}

Footer.propTypes = {
  author: PropTypes.string,
}

Footer.defaultProps = {
  author: ``,
}

export default Footer
