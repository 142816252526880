import React from "react"
import Styles from "./ready.module.scss"
import FukidashiImage from "../../images/top_ready_fukidashi.min.svg"
import PublisherImage from "../../images/top_ready_publisher.svg"
import ConsumerImage from "../../images/top_ready_consumer.svg"
import Figure from "src/components/figure.js"
import { Link } from "gatsby"

class Ready extends React.Component {
  render() {
    return (
      <div className={`hero ${Styles.ready}`}>
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-one-third">
                <div className={`is-flex ${Styles.title}`}>
                  <Figure src={FukidashiImage} alt="さあ、あなたも！" />
                  <h2 className="title is-size-4 has-text-white">今日から<br />ナタデココを<br />はじめましょう</h2>
                </div>
              </div>
              <div className="column is-one-third">
                <Card
                  title={<span>お持ちのWi-Fiスポットを<br />natadeCOCOスポットにする</span>}
                  image={PublisherImage}
                  imageAlt="配信を始める店員"
                  // linkTo="/flow#publisher"
                  linkTo="/flow"
                />
              </div>
              <div className="column is-one-third">
                <Card
                  title={<span>natadeCOCOスポットを<br />マップから探す</span>}
                  image={ConsumerImage}
                  imageAlt="利用を始めるお客様"
                  // linkTo="/flow#consumer"
                  linkTo="/flow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ready

const Card = ({ title, image, imageAlt, linkTo }) => (
  <div className={`box is-flex ${Styles.card}`}>
    <Figure src={image} alt={imageAlt} />

    <p className="content has-text-weight-bold has-text-centered">{title}</p>

    <Link className="has-text-white" to={linkTo}>
      <button className="button is-info is-fullwidth">
        <strong>手順を見る</strong>
      </button>
    </Link>
  </div>
)