import React from "react"
import { graphql } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Hero from "src/components/top/hero"
import Concept from "src/components/top/concept"
import News from "src/components/top/news"
import Points from "src/components/top/points"
import Extra from "src/components/top/extra"
import Ready from "src/components/top/ready"

const IndexPage = ({data}) => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Hero />
      <Concept />
      <News data={data} />
      <Points />
      <Extra />
      <Ready />
    </main>
    <Footer />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query pageQuery {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "news"} }},
      sort: { fields: [frontmatter___date], order: DESC },
      limit: 2
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            tag
            path
            title
          }
        }
      }
    }
  }
`
