import React from "react";
import Style from "./extra.module.scss";

import otodokemon from "../../images/otodokemon_main.png";

const Extra = () => {
  return (
    <div className="has-background-success" style={{ paddingTop: "4rem", paddingBottom: "4rem" }}>
      <div className="container">
        <a href="otodokemon/" className={`${Style.link} is-flex is-flex-direction-column is-align-items-center is-align-self-flex-start is-justify-content-center mx-auto`} style={{ width: "fit-content" }}>
          <img src={otodokemon} alt="おトドけもん" style={{ width: "520px", maxWidth: "90%", height: "auto", border: "solid 2px #2a2a2a", borderRadius: "0.5rem" }} />
          <p className="has-text-centered has-text-weight-bold is-size-7-mobile mt-3" style={{ color: "#2a2a2a" }}>おトドけもん | 配送ロボット × natadeCOCO</p>
        </a>
      </div>
    </div>
  )
};

export default Extra;