import React from "react"
import HeroTitleImage from "../../images/top_hero_title.svg"
import WomanLeftImage from "../../images/top_hero_woman_l.svg"
import ManImage from "../../images/top_hero_man.svg"
import WomanRightImage from "../../images/top_hero_woman_r.svg"
import Styles from "./hero.module.scss"
import Figure from "src/components/figure"

const Man = () => <Figure src={ManImage} alt="タブレットで画像を見る男性店員" />
const WomanMovie = () => <Figure src={WomanLeftImage} alt="スマホで動画を見る女性客" />
const WomanMusic = () => <Figure src={WomanRightImage} alt="スマホで音楽を聴く女性客" />

const Hero = () => (
  <div className={`hero is-fullheight ${Styles.topHero}`}>
    <div className="hero-body">
      <div className="container">
        <h1>
          <figure className={`image has-ratio ${Styles.title}`}>
            <img src={HeroTitleImage} alt="特別な場所をもーっと特別に" />
          </figure>
        </h1>
      </div>
    </div>

    <div className="hero-foot">
      {/* desktop */}
      <div className={`is-hidden-mobile container is-flex ${Styles.char}`}>
        <WomanMovie />
        <Man />
        <WomanMusic />
      </div>
      {/* mobile */}
      <div className={`is-hidden-tablet container is-flex ${Styles.charMobile}`}>
        <Man />
      </div>
    </div>
  </div>
)

export default Hero


