import React, { useState, useEffect } from "react"
// import Styles from "./header.module.scss"

import NavBar from "./navbar"

const Header = () => {
  const [navFixed, setNavFixed] = useState(false)

  const handleScroll = (e) => {
    checkFixedTop()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    checkFixedTop(true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  })

  const checkFixedTop = (init) => {
    if(navFixed !== (window.pageYOffset >= heightConsent())) {
        setBodyClass(!navFixed)
        setNavFixed(!navFixed)
    } else {
      init && setBodyClass(navFixed)
    }
  }

  const setBodyClass = (fixed) => {
    const consent = document.getElementById("consent")
    if(fixed){
      document.body.classList.add("has-navbar-fixed-top")
      consent && (consent.style.opacity = "0")
    }
    else{
      document.body.classList.remove("has-navbar-fixed-top")
      consent && (consent.style.opacity = "1")
    }
  }

  const heightConsent = () => {
    const consent = document.getElementById("consent")
    return consent ? consent.offsetHeight : 0
  }

  return (
    <header>
      <NavBar fixed={navFixed}/>
    </header>
  )
}

export default Header
