import React from "react"
import { Link } from "gatsby"
import Styles from "./points.module.scss"
import Spacer from "src/components/spacer"
import ManImage from "../../images/top_point_title_man.svg"
import WomanImage from "../../images/top_point_title_woman.svg"
import Point1PositiveImage from "../../images/top_point1_positive.svg"
import Point1NegativeImage from "../../images/top_point1_negative.svg"
import Point2PublisherImage from "../../images/top_point2_publisher.svg"
import Point2ConsumerImage from "../../images/top_point2_consumer.svg"
import Point3PublisherImage from "../../images/top_point3_publisher.svg"
import Point4OrderImage from "../../images/top_point4_order.min.svg"
import Point4InboundImage from "../../images/top_point4_inbound.min.svg"
import Point4EventImage from "../../images/top_point4_event.min.svg"
import Point4PricingImage from "../../images/top_point4_pricing.min.svg"
import Point4MarketingImage from "../../images/top_point4_marketing.min.svg"
import Figure from "src/components/figure.js"

const Points = () => (
  <div className="has-background-white">

    {/******************** HEADER ********************/}
    <div className={`hero ${Styles.head}`}>
      <div className="hero-body">
        <div className="container is-relative">

          <figure className={`image is-hidden-mobile ${Styles.char}`}>
            <img src={ManImage} alt="ポイントを紹介する店員" />
          </figure>

          <div className={`is-hidden-tablet has-background-white ${Styles.title} ${Styles.isMobile}`}>
            <h2 className="title is-size-5 has-text-primary has-text-centered">
              <span>ナタデ<DotText>ココ</DotText>の</span><span><DotText>ココ</DotText>がポイント！</span>
            </h2>
          </div>
          <div className={`is-hidden-mobile has-background-white ${Styles.title} ${Styles.isDesktop}`}>
            <h2 className="title is-size-4 has-text-primary has-text-centered">
              <span>ナタデ<DotText>ココ</DotText>の</span><span><DotText>ココ</DotText>がポイント！</span>
            </h2>
          </div>

          <figure className={`image is-hidden-mobile ${Styles.char}`}>
            <img src={WomanImage} alt="ポイントを紹介するお客様" />
          </figure>

        </div>
      </div>
    </div>

    {/******************** POINT1 ********************/}
    <div className={`hero ${Styles.point1}`}>
      <div className="hero-body">
        <div className="container">
          <Left><SubTitle index={1}>Wi-Fiに簡単接続<br />...だけじゃない！</SubTitle></Left>

          <div className="columns">
            <div className="column is-flex" style={{ flexDirection: "column", justifyContent: "space-between" }}>
              <div className="columns">
                <div className="is-hidden-mobile column is-1"></div>
                <div className="column">
                  <div className="content has-text-left has-text-centered-mobile">
                    <article className={Styles.text}>わざわざアクセス方法を探す必要ナシ。<br />QRコードorアプリ内マップからコンテンツに簡単アクセス！</article>
                  </div>

                  <div className={`is-hidden-tablet is-flex ${Styles.image}`} style={{ flex: "1" }}>
                    <figure className="image is-flex">
                      <img src={Point1PositiveImage} alt="QRコードでWI-FIに簡単接続するお客様" className="has-ratio" />
                    </figure>
                    <figure className="image is-flex">
                      <img src={Point1NegativeImage} alt="ログイン情報を入力する手間に苦しむお客様" className="has-ratio" />
                    </figure>
                  </div>

                  <div className="content has-text-left has-text-centered-mobile">
                    <article className={Styles.text}>Wi-Fiを使う*から、<br />リッチコンテンツも通信量を気にせずダウンロード！</article>
                  </div>
                  <div className="is-flex-mobile" style={{ justifyContent: "center" }}>
                    <div className={`is-flex ${Styles.richContents}`}>
                      <RitchContent title="動画" subTitle="大容量" iconClass="fas fa-play has-text-weight-bold" />
                      <RitchContent title="画像" subTitle="高画質" iconClass="fas fa-image has-text-weight-bold" />
                      <RitchContent title="音楽" subTitle="高音質" iconClass="fas fa-guitar has-text-weight-bold" />
                      <RitchContent title="アプリ" subTitle="多機能" iconClass="fas fa-tablet-alt has-text-weight-bold" />
                    </div>
                  </div>
                  <small className="has-text-centered-mobile">専用アプリをダウンロードする際には、お客様自身の契約回線の通信料が発生することがあります。</small>
                </div>
              </div>
            </div>

            <div className="is-hidden-mobile column is-flex">
              <div className={`is-flex ${Styles.image}`} style={{ flex: "1", marginTop: "-2.5rem" }}>
                <figure className="image is-flex">
                  <img src={Point1PositiveImage} alt="QRコードでWI-FIに簡単接続するお客様" className="has-ratio" />
                </figure>
                <figure className="image is-flex">
                  <img src={Point1NegativeImage} alt="ログイン情報をて入力する手間に苦しむお客様" className="has-ratio" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/******************** POINT2 ********************/}
    <div className={`hero ${Styles.point2}`}>
      <div className="hero-body">
        <div className="container">
          <Right><SubTitle index={2}>だれでもいつでも<br />気軽にスタート</SubTitle></Right>

          <div className="columns">
            <div className="column">
              <User
                image={Point2PublisherImage}
                imageAlt="Wi-Fiとスマホがあればコンテンツを配信できる店員"
                action="配信"
                descriptions={[
                  "Wi-FiとスマホがあればOK",
                  "大掛かりな工事は必要ナシ",
                  "シンプルな料金プランで安心",
                ]}
                buttonTitle="料金プランを見る"
                path="/price/"
              />
            </div>
            <div className="column">
              <User
                image={Point2ConsumerImage}
                imageAlt="スマホだけでコンテンツを利用できるお客様"
                action="利用"
                descriptions={[
                  "必要なのはスマホだけ",
                  "無料アプリ１つでどこでも使える",
                ]}
                buttonTitle="無料アプリの詳細を見る"
                path="/product/pocket/"
              />
            </div>
          </div>

        </div>
      </div>
    </div>

    {/******************** POINT3 ********************/}
    <div className={`hero ${Styles.point3}`}>
      <div className="hero-body">
        <div className="container">
          <Left><SubTitle index={3}>旬なコンテンツを<br />すぐに配信</SubTitle></Left>
          
          <div className="columns">
            <div className="column is-flex" style={{ flexDirection: "column", justifyContent: "space-between" }}>
              <div className="columns">
                <div className="is-hidden-mobile column is-1"></div>
                <div className="column">
                  <div className="content has-text-left has-text-centered-mobile">
                    <article className={Styles.text}>コンテンツは管理コンソールから簡単アップロード。<br />あなたの「今」伝えたい想いをすぐに届けられます。</article>
                  </div>
                  <div className="is-hidden-tablet">
                    <Point3Image />
                  </div>
                  <div className={Styles.topics}>
                    <Topic
                      title={<span>広く拡散されたくない<br />「ここだけ」の情報を配信！</span>}
                      text={<span>その場にいる人だけがアクセスできるので、<br />安心してコンテンツを配信できます。</span>}
                      iconClass="fas fa-lock"
                    />
                    <Topic
                      title={<span>コンテンツの制作に<br />自信がなくても大丈夫！</span>}
                      text={<span>クリエイター紹介などのバックアップ体制も充実。<br />ハイクオリティなコンテンツ制作を応援します。</span>}
                      iconClass="fas fa-code"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-flex" style={{flexDirection: "column"}}>
              <div className="is-hidden-mobile is-flex"  style={{ marginTop: "-2.5rem", flex: "1", alignItems: "center" }}>
                <Point3Image />
              </div>
              <div className="has-text-centered-mobile has-text-right-tablet">
                <Link to="/product/console/">
                  <button className="button is-info" style={{marginTop: "2rem"}}>
                    <strong>管理コンソールの詳細を見る</strong>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/******************** POINT4 ********************/}
    <div className={`hero ${Styles.point4}`}>
      <div className="hero-body">
        <div className="container">
          <Center><SubTitle index={4}>さまざまな<br />シーンで大活躍</SubTitle></Center>

          <div className={Styles.scenes}>
            <div className="columns is-mobile is-1">
              <div className="column is-one-third"><Scene titleFirst="モバイル" titleSecond="オーダー" image={Point4OrderImage} /></div>
              <div className="column is-one-third"><Scene titleFirst="イベント" titleSecond="集客" image={Point4EventImage} /></div>
              <div className="column is-one-third"><Scene titleFirst="アンケート" titleSecond="調査" image={Point4MarketingImage} /></div>
            </div>
            <div className="columns">
              <div className="column is-two-third">
                <div className="columns is-mobile is-1">
                  <div className="column is-half"><Scene titleFirst="ダイナミック" titleSecond="プライシング" image={Point4PricingImage} /></div>
                  <div className="column is-half"><Scene titleFirst="インバウンド" titleSecond="対応" image={Point4InboundImage} /></div>
                </div>
              </div>
              <div className="column is-one-third">
                <div className={`is-flex ${Styles.extra}`}>
                  <div className="content">
                    <p className="has-text-primary has-text-weight-bold has-text-centered">用途は他にも<br />まだまだいっぱい</p>
                    <Spacer isHalf className="is-hidden-mobile" />
                    <div className="has-text-centered">
                      <Link to="/casestudy">
                        <button className="button is-info"><strong>活用例を見る</strong></button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    
  </div>
)

export default Points

////////////////////////////////////////////////// 
//                    common
//////////////////////////////////////////////////

const SubTitle = ({index, children}) => (
  <div className={`is-flex-tablet has-text-centered-mobile ${Styles.subTitle}`}>
    <div className={`is-size-2 is-size-3-mobile has-text-white has-text-centered-mobile is-relative ${Styles.index}`}>
      <span className={`is-hidden-mobile ${Styles.hukidashi}`}></span>{index}
    </div>
    <h3 className="title is-4 is-size-5-mobile">{children}</h3>
  </div>
)

const DotText = ({children}) => <span className={Styles.dotText}>{children}</span>

////////////////////////////////////////////////// 
//                    point1
//////////////////////////////////////////////////

const RitchContent = ({title, subTitle, iconClass}) => (
  <div className={`has-text-centered ${Styles.content}`}>
    <p className={`has-text-primary has-text-weight-bold is-size-7 ${Styles.subTitle}`}>{subTitle}</p>
    <p className={`has-text-primary has-text-weight-bold ${Styles.title}`}>{title}</p>
    <span className={`icon is-large has-background-primary ${Styles.icon}`}>
      <i className={`${iconClass} fa-2x fa-inverse`}></i>
    </span>
  </div>
)

////////////////////////////////////////////////// 
//                    point2
//////////////////////////////////////////////////

const User = ({ image, imageAlt, action, descriptions, buttonTitle, path }) => (
  <div className={`is-flex ${Styles.user}`}>

    <div className={`is-relative is-flex ${Styles.position} ${Styles.center} ${Styles.image}`}>
      <div className={`has-text-weight-bold is-size-7 has-text-centered ${Styles.title}`}>コンテンツを<br /><strong className="is-size-5 has-text-primary">{action}</strong>したい</div>
      <Figure src={image} alt={imageAlt} />
    </div>

    <div className={`is-flex ${Styles.content}`}>
      <div className={`content has-text-centered is-flex ${Styles.position} ${Styles.center}`}>
        <ul>
          {descriptions.map((description, index) => <li key={index}>{description}</li>)}
        </ul>
      </div>

      <div className={`content is-relative is-flex ${Styles.position} ${Styles.center}`}>
        <Link to={path}>
          <button className="button is-info">
            <strong>{buttonTitle}</strong>
          </button>
        </Link>
      </div>
    </div>
  </div>
)

////////////////////////////////////////////////// 
//                    point3
//////////////////////////////////////////////////

const Topic = ({title, text, iconClass}) => (
  <div className={Styles.topic}>
    <article className={`media ${Styles.topicHeader}`}>
      <span className={`media-left icon is-large has-background-primary ${Styles.icon}`}>
        <i className={`${iconClass} fa-lg fa-inverse`}></i>
      </span>
      <h4 className={`media-content is-size-6-mobile has-text-weight-bold has-text-primary ${Styles.title}`}>{title}</h4>
    </article>
    <p className="is-size-6">{text}</p>
  </div>
)

const Point3Image = () => (
  <div className={Styles.image}>
    <Figure src={Point3PublisherImage} alt="コンテンツを配信する店員" className="has-ratio" />
  </div>
)

////////////////////////////////////////////////// 
//                    point4
//////////////////////////////////////////////////

const Scene = ({ titleFirst, titleSecond, image }) => (
  <div className={Styles.scene}>
    <p className="is-size-7-mobile has-text-primary has-text-weight-bold has-text-centered">{titleFirst}<br />{titleSecond}</p>
    <div className={Styles.image}>
      <div className="has-background-primary">
        <Figure src={image} alt={`${titleFirst}${titleSecond}のイメージ`} />
      </div>
    </div>
  </div>
)

const Left = ({ children }) => <div className={`is-flex-tablet ${Styles.position} ${Styles.left}`}>{children}</div>
const Center = ({ children }) => <div className={`is-flex-tablet ${Styles.position} ${Styles.center}`}>{children}</div>
const Right = ({ children }) => <div className={`is-flex-tablet ${Styles.position} ${Styles.right}`}>{children}</div>