import React from "react"
import Styles from "./concept.module.scss"
import Wrap from "src/components/wrap"
import TitleImage from "../../images/top_concept_title.svg"
import movie from "../../images/top_concept.mp4"
import Figure from "src/components/figure.js"

const Concept = () => (
  <div className={`has-background-success ${Styles.concept}`}>

    <div className={`is-hidden-desktop hero is-relative ${Styles.isMobile} ${Styles.conceptHead}`}>
      <Head />
    </div>
    <div className={`is-hidden-touch hero is-relative ${Styles.isDesktop} ${Styles.conceptHead}`}>
      <Head />
    </div>

    <div className={`hero ${Styles.conceptBody}`}>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-relative">

            <div className="column is-5" style={{ margin: "2rem 0" }}>
              <div className="columns">
                <div className="is-hidden-mobile column is-1"></div>
                <div className="column">
                  <div className={`content has-text-centered-mobile has-text-primary has-text-weight-bold ${Styles.about}`}>
                    <Wrap>
                      <span>natadeCOCOは </span>
                      <span><StrongLabel>指定したWi-Fiエリア<span className="icon is-medium has-text-white"><i className="fas fa-wifi"></i></span></StrongLabel>で</span><br />
                      <span><StrongLabel>デジタルコンテンツを配信</StrongLabel></span>
                      <span>できる</span><span>サービスです。</span>
                    </Wrap>
                  </div>
                  <div className="is-hidden-tablet" style={{ margin: "0 auto" }}>
                    <video style={{ borderRadius: "50%" }} src={movie} autoPlay loop muted playsInline></video>
                  </div>
                  <div className={`content has-text-centered-mobile has-text-weight-bold ${Styles.message}`}>
                    <Wrap>コンテンツへアクセスできるのは、<br />そのエリアにいる人だけ。</Wrap>
                    <Wrap>その「場所」でしかできない<span>体験を、</span><br />スマホ１つで、もっと簡単に。<br />もっと特別に。</Wrap>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-hidden-mobile" style={{margin: "0 auto" }}>
              <video style={{ borderRadius: "50%" }} src={movie} autoPlay loop muted playsInline></video>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
)

export default Concept

const StrongLabel = ({ children }) => <strong className="tag is-medium is-primary" style={{ marginLeft: ".25em", marginRight: ".25em" }}>{children}</strong>

const Head = () => (
  <div className="hero-body" style={{ paddingBottom: "0" }}>
    <div className="container">
      <div className="columns">
        <div className="column is-half">
          <Figure src={TitleImage} alt="お越しいただいた方に特別な体験を提供したい、そんな想いをnatadeCOCOが叶えます！" />
        </div>
        <div className="column"></div>
      </div>
    </div>
  </div>
)