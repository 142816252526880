import React from "react"
import Styles from "./news.module.scss"
import { Link } from "gatsby"
import { formatDate } from 'src/util/dateUtil';

const News = ({ data }) => (
  <div className={`hero ${Styles.news}`}>
    <div className="hero-body">
      <div className="container">
        <h2 className={`title has-text-centered has-text-white ${Styles.title}`}>
          NEWS<small className="is-size-7">お知らせ</small>
        </h2>

        <div className={Styles.list}>
          {data && data.allMarkdownRemark.edges.map((edge, index) => {
            const { date, tag, path, title } = edge.node.frontmatter
            return (
              <Link className="has-text-white" to={path} key={index}>
                <div className="columns">
                  <div className="column is-two-fifths">
                    <div className="columns is-flex-mobile">
                      <div className="column is-three-fifths has-text-centered has-text-left-mobile is-size-7-mobile">{formatDate(date)}</div>
                      <div className="column has-text-left is-hidden-mobile"><Tag tagName={tag}></Tag></div>
                    </div>
                  </div>
                  <div className="column has-text-weight-bold">{title}</div>
                </div>
              </Link>
            )
          })}
        </div>

        <div className="columns is-centered">
          <div className="column is-one-third">
            <Link to="/news/">
              <button className="button is-info is-fullwidth">
                <strong>もっと見る</strong>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default News

const Tag = ({ tagName }) => {
  let color
  switch (tagName) {
    case "お知らせ":
      color = "is-white"
      break;
    // ここにcaseを増やしていく 
    default:
      color = "is-white"
      break;
  }
  return <div className={`tag is-rounded has-text-primary has-text-weight-bold ${color}`}>{tagName}</div>
}